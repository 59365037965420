/*color pallete*/
/*b8d431*/
/*27aae0*/
/*6ccff6*/
/*001011*/
/*757780*/
.App {
  text-align: center;
}

.pilotRow{
    text-align: center;
    font-size: 2.3em;
    width: 100%;
}

.skykandyGreen {
    color:#b8d431 ;
}

.skykandyBlue {
    color:#27aae0 ;
}

body,html{
  font-family: 'Lato', sans-serif !important;
  height:100%;
  -webkit-font-smoothing: antialiased;
}
.signupBox{
  min-width: 100px;
  width: 60%;
  margin: 30px auto 0 auto;
  border-radius: 4px;
  opacity: 0.99;
    height: 40px;
    line-height: 40px;
    padding-left: 20px;
}

.signupButton{
    padding: 10px;
    border: solid 2px white;
    background-color: transparent;
    color:white;
    margin-top: 10px;
}

.signupButton:hover {
  background-color: white;
  color:black;
}

.mainfooter{
  background-color: black;
  margin-bottom: 0;
  padding-top: 35px;
  padding-bottom: 35px;
}

.footer{
  background-color: #27aae0;
  height: 400px;
}
.iconRow{
  padding-top: 80px;
  padding-bottom: 200px;
}
body,html{
  font-family: Montserrat, Arial, Helvetica, sans-serif;
  overflow-x: hidden;
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.home-page-pic-overlay{
  background-color:rgba(1,113,159,0.2);
  height:744px;
  width: 105%;
  margin-left: -25px;
}

.home-page-pic-overlay-2{
  background-color:rgba(3,16,17,0.7);
  height:744px;
  width: 105%;
  margin-left: -25px;
}

.home-page-aircraft-div{
   text-align: center;
 }

.home-page-aircraft-div h2{
  font-size: 2em;
}

.home-page-aircraft-div p{
  font-weight: 400;
}

.home-page-hr{
  background-color: #d3d3d3;
  width: 50px;
}

.about-us{
  margin-top: 95px;
  margin-bottom: 95px;
}

.frontPageBlueHeading{
  margin-bottom: 10px;
  margin-top: 25px;
  font-weight: 300;
    font-size: 1.6em;
    text-align: center;
}

.jusitifiedSubHeader{
  text-align: center;
  font-size: 1.2em;
}

.videoTitle{
  margin-top: 90px;
  margin-bottom: 30px;
}
.headerIcon{
  height: 100px;
  width: 100px;
  margin: 80px auto 10px auto;
}

.greyText{
  color:#757780;
}

.frontPageHeader{
  padding-top: 150px;
  font-size: 6.5em;
  font-weight: 300;
  padding-bottom: 20px;
}

.bythepeoplesubheader{
   text-align: center;
  width: 80%;
  margin: 0 auto;
  font-size: 1.2em;
 }

.bythepeopleheader{
  text-align: center;
  width: 100%;
  color:#757780;
  font-weight: 600;
  font-size: 1.5em;
}
.frontPageSubHeader{
  font-size: 3.2em;
  font-weight: 300;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.field-error{
  color:red;
  margin-top: 3px;
}

.profile{
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #b8d431;
}

.aircraftPayRow{
  margin-top: 25px;
  margin-bottom: 25px;
}

.betaBrand{
    color:#27aae0;
    font-size: 1.3em;
    font-weight: bold;
}

.dropdown-menu {
  right: 0 !important;
  left: auto !important;
}
.blurred-border-bottom{
  -webkit-box-shadow: 0 4px 6px -6px #222;
  -moz-box-shadow: 0 4px 6px -6px #222;
  box-shadow: 0 4px 6px -6px #222;
  margin-bottom: 40px;
}

.whiteText{
  color:#ffffff;
}
.react-autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  top: 51px;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.linkText:hover{
  cursor: pointer;
  text-decoration: none;
  color: #27aae0;
}

.whiteText{
  color: white;
}
.linkText{
  text-decoration: none;
  font-weight: 600;
  text-underline: none;
}

.half-width-title{
  width: 60%;
    padding-top: 150px;
}

.emailResponse{
   color: #27aae0;
   margin-top:50px ;
}

.top-bottom-pad{
  margin-top: 10px;
  margin-bottom: 20px;
}

.profileName{
  color: #b8d431;
}

.greenText{
  color: #b8d431;
}

.formLabel{
  font-weight: 600;
}

.insuranceDiv{
  margin-bottom: 20px;
}
.greenButton{
  background-color: #b8d431 !important;
  border-color: #b8d431 !important;
}
.profilePaddedRow{
  margin-top: 7px;
  margin-bottom: 7px;
}

.centeredElement{
  display: block;
  margin: 0 auto;
}

.blueText{
  color: #27aae0;
}

.text-right{
  text-align: right;
}
.formBorderTitle{
  margin-top: 10px;
  border-bottom: solid 1px #27aae0;
  padding-bottom: 8px;
  margin-bottom: 20px;
}

.signup-link:hover{
  text-decoration: none;
}

.pilot-complete-icon{
  width: 100%;
}

.signup-status{
  margin-right: 3px;
}

.incomplete{
  color:#F44336;
}

.inReview{
  color: orange;
}

.complete{
  color:#4caf50;
}

.red-icon{
  color:#F44336;
}

.green-icon{
  color:#4caf50;
}
/*.react-datepicker__input-container input[type="text"]{*/
  /*border:0 !important;*/
  /*border-bottom-color: #ffffff !important;*/
  /*border-left-color: #ffffff !important;*/
  /*border-top-color: #ffffff !important;*/
  /*border-right-color: #ffffff !important;*/
  /*padding-left: 10px;*/
/*}*/

/*.react-datepicker__input-container{*/
  /*border-bottom-color:rgb(206, 212, 218) !important;*/
  /*border-bottom-left-radius:4px !important;*/
  /*border-bottom-right-radius:4px !important;*/
  /*border-bottom-style:solid !important;*/
  /*border-bottom-width:0.994318px !important;*/
  /*border-image-outset:0px !important;*/
  /*border-image-repeat:stretch !important;*/
  /*border-image-slice:100% !important;*/
  /*border-image-source:none !important;*/
  /*border-image-width:1 !important;*/
  /*border-left-color:rgb(206, 212, 218) !important;*/
  /*border-left-style:solid !important;*/
  /*border-left-width:0.994318px !important;*/
  /*border-right-color:rgb(206, 212, 218) !important;*/
  /*border-right-style:solid !important;*/
  /*border-right-width:0.994318px !important;*/
  /*border-top-color:rgb(206, 212, 218) !important;*/
  /*border-top-left-radius:4px !important;*/
  /*border-top-right-radius:4px !important;*/
  /*border-top-style:solid !important;*/
  /*border-top-width:0.994318px !important;*/
/*}*/

/*.react-datepicker-ignore-onclickoutside{*/
  /*border: 0 !important;*/
  /*border-bottom-color: #ffffff !important;*/
  /*border-left-color: #ffffff !important;*/
  /*border-top-color: #ffffff !important;*/
  /*border-right-color: #ffffff !important;*/
  /*border-image-width: 0 !important;*/
/*}*/

/*.react-datepicker-wrapper{*/
  /*border: 0 !important;*/
  /*border-bottom-color: #ffffff !important;*/
  /*border-left-color: #ffffff !important;*/
  /*border-top-color: #ffffff !important;*/
  /*border-right-color: #ffffff !important;*/
/*}*/

input:disabled {
  color: #e9ecef;
  cursor: default;
  background-color: #e9ecef;
}

.left-pad-8{
  padding-left: 18px;
}

.SingleDatePickerInput__withBorder{
  border-radius: 2px !important;
  border: solid 1px gray !important;
}

.rounded{
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-color:#757780;
}

.calendarDay{
     border:solid 1px black;
     height:100px;
 }

.calendarDay:hover{
    cursor: pointer;
}

.unavail-header{
    font-weight: bold;
}

.calendarDayHeader{
    text-align: center;
    margin-bottom: 13px;
}

.profilePicture{
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: solid black 1px;
}

.profilePictureView{
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: solid black 1px;
}

.driversLicensePreview{
  width: 300px;
  height: 300px;
  display: block;
  margin-bottom: 15px;
}

.profileHr{
  margin-top: 40px;
  margin-bottom: 40px;
}

.spaced{
    margin-top: 60px;
    margin-bottom: 60px;
}

.planeCalendarRightWindow{
    width:25%;
    height:100%;
    display: inline-block;
    vertical-align:top;
    border-left: solid 1px;
    padding-left: 20px;
}
.planePriceCalendar{
    width:50%;
  height:100%;
    display: inline-block;
    vertical-align:top;
    padding-right: 20px;
}

.planePhotos{
  width:79%;
  height:100%;
  display: inline-block;
  vertical-align:top;
  padding-right: 20px;
}

.planeAdminPageWrapper{
  width:79%;
  height:100%;
  display: inline-block;
  vertical-align:top;
  padding-right: 20px;
}

.highlighted-menu-item{
  color: #757780;
  font-weight: 600;
}
.planeAdminCalendarMenu{
  display: inline-block;
  width: 15%;
}
.place-calendar-click-arrow{
    display: inline-block;
    padding: 0 3px 0 3px;
    font-size: 1.5em;
}
.place-calendar-click-arrow:hover{
    cursor: pointer;
}

.planeCalendarDateTitle{
    font-weight: bold;
}

.calendarNavDate{
  width: 100%;
  font-weight: bold;
  font-size: 1.1em;
  display: inline-block;
}

iframe{
  max-width: 95% !important;
}

.calendar-day-with-unavailability{
  background-color: #e0e0e0;
}

.invalid-plane-calendar-date{
  background-color: white;
  color: white;
}

.selected-calendar-day{
    background-color: #B4CDCD;
}

.cursor-pointer:hover{
  cursor: pointer;
}

.top-padded-button{
  margin-top: 9px;
}

.calendarNavPrice{
  font-weight: bold;
}

.add-unavailable-link{
  color: #007bff;
}

.loadingGif{
  display: block;
  width: 50px;
  margin: 0 auto;
}

.add-unavailable-link:hover{
  cursor: pointer;
}

.plane-admin-heading{
  margin-bottom: 35px;
}

.managed-plane-image{
  display: inline-block;
  padding: 10px;
}

.right-plane-admin-cancel{
  font-weight: bold;
  font-size: 1.4em;
  float: right;
  margin-bottom: 25px;
}

.request-alert{
  position: absolute;
  top: 20px;
  left:0;
  right:0;
  margin-left:auto;
  margin-right:auto;
  min-width: 150px;
  padding: 15px;
  border: 1px solid transparent;
  display: block;
  width: 400px;
  border-radius: 3px;
}

.close-alert {
  -webkit-appearance: none;
  position: relative;
  float: right;
  padding: 0;
  border: 0;
  cursor: pointer;
  color: inherit;
  background: 0 0;
  font-size: 21px;
  line-height: 1;
  font-weight: bold;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.7);
  filter: alpha(opacity=40);
  opacity: .4;
}

.marginTop{
  margin-top: 60px;
}

.alert-success{
  background-color: #3db66f !important;
  border-color: #80c780;
  color: #ffffff !important;
}

.alert-error{
  background-color: #b71c1c!important;
  border-color: #c62828;
  color: #ffffff !important;
}

@media only screen and (max-width: 800px) {
  #skyKandyLogo {
    width:100px;
  }

}

@media only screen and (max-width: 700px) {
  .betaBrand{
    margin-left: -170px;
  }

}

@media only screen and (max-width: 650px) {
  .frontPageSubHeader{
    font-size: 2.2em;
  }

  .home-page-pic-overlay{
    background-color:rgba(1,113,159,0.2);
    height:744px;
    width: 115%;
    margin-left: -25px;
  }

  .home-page-pic-overlay-2{
    background-color:rgba(3,16,17,0.7);
    height:744px;
    width: 115%;
    margin-left: -25px;
  }

  .mobileMargin{
    margin-top: 25px;
  }

  .betaBrand{
    font-size: 0.6em;
    margin-left: -170px;
    margin-top: 10px;
  }

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a{
  padding-left: 3px;
}